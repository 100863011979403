<template>
  <b-card>
    <b-row>
      <b-col md="12">
        <div
          v-if="dataLoad"
          class="text-center my-2"
        >
          <b-spinner
            type="grow"
            label="Loading..."
          />
        </div>
        <div
          v-else
          class="table-wrapper mt-2"
        >
          <table class="certificate-table">
            <thead>
              <th
                v-for="column in columns"
                :key="column.key"
                class="text-center"
                style="background-color: #f3f2f7;"
              >
                {{ column.label || column.key }}
              </th>

            </thead>
            <tbody>
              <template v-for="(items, category) in categorizedItems">
                <tr :key="category">
                  <td
                    colspan="5"
                    class="font-weight-bold text-center"
                    :style="getCategory(category)"
                  >
                    {{ category }}
                  </td>
                </tr>
                <tr
                  v-if="items.length === 0"
                  :key="`${category}-no-data`"
                >
                  <td
                    colspan="5"
                    class="text-center text-muted"
                  >
                    No data in this certificate
                  </td>
                </tr>
                <tr
                  v-for="item in items"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.key"
                    class="text-center"
                  >
                    <span v-if="column.key === 'engineer_names'">
                      <feather-icon
                        icon="UsersIcon"
                        size="16"
                        class="mr-50 cursor-pointer"
                        @click="ApprovalRow(item.engineer_names)"
                      />
                    </span>
                    <span v-else-if="column.key === 'actions_view'">
                      <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer"
                        @click="
                          $router.push({
                            name: 'certificate',
                            params: { MCR_id: item.id , Certificate_Name: item.name.en }
                          })
                        "
                      />
                    </span>
                    <span v-else>
                      {{ getValue(item, column.key) }}
                    </span>
                    <span v-if="NoData">
                      No data in this certificate
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-between my-2"
        >
          <div>
            Results: <span class="font-weight-bold">{{ totalRows }}</span>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      dataLoad: false,
      NoData: false,
      totalRows: '',
      columns: [
        { key: 'id', sortable: false },
        { key: 'name.ar', label: 'Certificate Name AR' },
        { key: 'name.en', label: 'Certificate Name EN' },
        { key: 'engineer_names', label: 'Engineers' },
        { key: 'actions_view', label: 'Certificate Details' },
      ],
      categorizedItems: {},
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getCategory(category) {
      const categoryColors = {
        SFDA: 'background: linear-gradient(to right, #6e1fd4 , #0b0315); color: white;',
        Jeem: 'background: linear-gradient(to right, #b3661c , #ed9339); color: white;',
        Saber: 'background: linear-gradient(to right, #8c5a78 , #401b35); color: white;',
        Moiat: 'background: linear-gradient(to right, #ff0000 , #7f0000); color: white;',
        SLS: 'background: linear-gradient(to right, #33c9d4 , #2bb1ba);  color: white;',
        GOEIC: 'background: linear-gradient(to right, #f09e07 , #ffff00);  color: white;',
        MOPH: 'background: linear-gradient(to right, #1976d2 , #64b5f6);  color: white;',
      }
      const defaultColor = 'background: linear-gradient(to right, #e3e3e3 , #212121); color: white;'

      return categoryColors[category] || defaultColor
    },
    getValue(item, key) {
      const keys = key.split('.')
      let value = item
      keys.forEach(k => {
        value = value ? value[k] : undefined
      })
      return value
    },
    formatEngineerNames(names) {
      return `
        <table style="border: 0.5px solid; border-collapse: collapse; margin-top: 18px !important;  margin: auto;">
          <tr style="background-color: #f3f2f7">
            <th style="border: 0.5px solid; padding: 8px; text-align: left;">#</th>
            <th style="border: 0.5px solid; padding: 8px; text-align: left;">Engineer Names</th>
          </tr>
          ${names.split(', ').map((name, index) => `
            <tr>
              <td style="border: 1px solid; padding: 8px;">${index + 1}</td>
              <td style="border: 1px solid; padding: 8px; text-align: left !important; text-wrap: wrap !important;">${name}</td>
            </tr>`).join('')}
        </table>
      `
    },
    ApprovalRow(name) {
      const getName = String.raw`${name}`
      this.$swal({
        title: 'Engineer List',
        html: this.formatEngineerNames(getName),
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Close',
      })
    },
    async getData() {
      this.dataLoad = true
      try {
        const response = await axios.get('certificate_group')

        if (response.status === 200) {
          const responseData = decryptData(response.data.payload)
          const data = responseData?.data[Object.keys(responseData?.data)[0]] || []
          const sources = [...new Set(data.map(item => item.source))]

          sources.forEach(source => {
            this.categorizedItems[source] = data.filter(item => item.source === source)
          })
          this.totalRows = responseData.data.count || data.length
        }
      } catch (error) {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.dataLoad = false
      }
    },
  },
}
</script>

<style lang="scss">
.table-wrapper {
  max-height: 780px;
  overflow-y: auto;
  border: 1px solid #ddd;
}

.certificate-table {
  width: 100%;
  border-collapse: collapse;
}

.certificate-table thead {
  position: sticky !important;
  top: 0;
  background: #f3f2f7;
  z-index: 3;
}

.certificate-table tr:hover {
  background-color: #297dd6;
  color: white;
}

.certificate-table th,
.certificate-table td {
  padding: 2px;
  text-align: left;
  border: 1px solid #ddd;
}
</style>
